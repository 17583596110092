// import React from 'react';
// import './Loader.scss';
// import Logo from 'images/new-images/favicon-removebg-preview.png'

// const Loader: React.FC = () => {
//   return (
//     <div className="loader-container">
//       <div className="loader-ring"></div>
//       <img
//         src={Logo}
//         alt="Logo"
//         className="loader-logo"
//       />
//     </div>
//   );
// };

// export default Loader;
import React from 'react';
import './Loader.scss';
import Logo from 'images/new-images/favicon-removebg-preview.png';

const Loader: React.FC = () => {
  return (
    <div className="loader-container flex items-center justify-center " style={{ zIndex:'-1' }}>
      <div className="loader-ring" style={{ zIndex:'-1' }}></div>
        <img src={Logo} alt="Logo" className="w-[6rem] h-[6rem] z-50 -ml-[6rem]" />
    </div>
  );
};

export default Loader;
