import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'



export const GetCategory:any = createAsyncThunk('/categories', async (data:any,thunkAPI) => {
    
  
  const baseUrl = process.env.REACT_APP_BASE_URL;

 try {
    const response = await axios.get(`${baseUrl}/categories` )
   
  return { getcategory: response?.data?.data}
  } catch (error) {
    
  }
})

export const CategoriesData = createSlice({
  name: 'CategoryData',
  initialState: {
    getcategory: [],
  
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(GetCategory.fulfilled, (state, action:any) => {
      state.getcategory = action.payload?.getcategory
      })
  
  }
})

export default CategoriesData.reducer