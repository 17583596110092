import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'



export const GetBanner:any = createAsyncThunk('/types/8', async (data:any,thunkAPI) => {
    
  
  const baseUrl = process.env.REACT_APP_BASE_URL;

 try {
    const response = await axios.get(`${baseUrl}/types/8` )
   
  return { getBanner: response?.data}
  } catch (error) {
    
  }
})

export const BannerSlice = createSlice({
  name: 'BannerData',
  initialState: {
    getBanner: [],
  
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(GetBanner.fulfilled, (state, action:any) => {
      state.getBanner = action.payload.getBanner
      })
  
  }
})

export default BannerSlice.reducer