import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const GetTags: any = createAsyncThunk(
  "/tags",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(`${baseUrl}/tags`);

      return { getTags: response?.data };
    } catch (error) {}
  }
);

export const LatestUpdatesDataSlice = createSlice({
  name: "LatestUpdates",
  initialState: {
    getTags: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetTags.fulfilled, (state, action: any) => {
      state.getTags = action.payload.getTags;
    });
  },
});

export default LatestUpdatesDataSlice.reducer;
