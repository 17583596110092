// store.ts
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import cartReducer from "../features/cartslice";
import wishlistReducer from "../features/wishlistslice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import shippingreducer from "../features/shippingformslice";
import billingreducer from "../features/billingformslice";
import userdetailsreducer from "../features/userinfo";
import setcurrencyreducer from "../features/setcurrency";
import apireducer from "../features/apiSlice";
import AllShopData from "../components/Allshops/store";
import LatestUpdate from "../components/SectionHowItWork/store";
import BannerData from "../components/SectionHero/store";
import CategoryData from "../components/SectionSliderCategories/store";
import ContainerData from "../containers/store"
import OrderDataSlice from '../containers/AccountPage/store'
export type RootState = ReturnType<typeof store.getState>;

const persistConfig = {
  key: "wishlist",
  storage,
};

const rootReducer = combineReducers({
  cart: cartReducer,
  wishlist: wishlistReducer,
  shippingform: shippingreducer,
  billingform: billingreducer,
  userinfonewreducer: userdetailsreducer,
  currencyreducer: setcurrencyreducer,
  api: apireducer,
  AllShopData: AllShopData,
  LatestUpdates: LatestUpdate,
  BannerData: BannerData,
  CategoryData: CategoryData,
  ContainerData: ContainerData,
  OrderDataSlice:OrderDataSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: {
    persistedReducer,
    // shippingform:shippingReducer,

    // cart: cartReducer,
    // wishlist:wishlistReducer,
  },
});

export default store;
