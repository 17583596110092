import React, { useEffect, useState } from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";
// import Loader from "components/Loader/Loader";
import { FaWhatsapp } from "react-icons/fa";
import CookieBanner from "components/Cookies/CookieBanner";
import { GetMeAPI, GetSettings, GetTypes } from "containers/store";
import { useDispatch } from "react-redux";
import { GetTags } from "components/SectionHowItWork/store";
import Loader from "components/Loader/Loader";
function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200); // Change the time as needed
  }, []);

  const userToken = localStorage.getItem("token");

  useEffect(() => {
    dispatch(GetTypes());
    dispatch(GetTags());
    dispatch(GetSettings());
    // dispatch(GetGazette(1));

    if (userToken) {
      dispatch(GetMeAPI());
    }
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Anmol</title>
          <meta name="description" content="Anmol Silver jewellers" />
        </Helmet>

        <>
          {loading && <Loader />}

          {
            <div className={`${loading ? "invisible" : ""}`}>
              {/* <div> */}
              <div className="fixed bottom-4 right-1 z-50">
                <a
                  href="https://api.whatsapp.com/send?l=en&phone=919426014924&text=Hey there, I have a question!"
                  target="_blank"
                  rel="noopener noreferrer"
                  className=""
                >
                  <div className="p-2 bg-green-500 rounded-full text-white">
                    <FaWhatsapp size={24} />
                  </div>
                </a>
              </div>
              <div className=" bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
                <MyRouter />
                <CookieBanner />
              </div>
            </div>
          }
        </>
      </HelmetProvider>
    </>
  );
}

export default App;
