
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CurrencyState {
  currency: any;
}

const initialState: CurrencyState = {
  currency: 0,
};

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    SetCurrency: (state, action: PayloadAction<number>) => {
      state.currency = action.payload;
    },
    incrementCurrency: (state, action: PayloadAction<number>) => {
      state.currency += action.payload;
    },
    decrementCurrency: (state, action: PayloadAction<number>) => {
      state.currency -= action.payload;
    },
  },
});

export const { SetCurrency, incrementCurrency, decrementCurrency } = currencySlice.actions;

export default currencySlice.reducer;