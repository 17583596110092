import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const GetCategoriesByID: any = createAsyncThunk(
  "/categories",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    if (data?.id && data?.currency && data?.page) {
      try {
        const response = await axios.get(
          `${baseUrl}/get-product-by-category/${data?.id}?currency=${data?.currency}&page=${data?.page}`
        );

        // console.log(response?.data, 'res')
        return { getCategoriesByID: response?.data };
      } catch (error) {}
    }
  }
);
export const GetCategoriesByLimit: any = createAsyncThunk(
  "/categoriesbyLimit",
  async () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(`${baseUrl}/categories?limit=500`);

      // console.log(response?.data, 'res')
      return { getCategoriesByLimit: response?.data?.data };
    } catch (error) {}
  }
);

export const GetCategories2ByID: any = createAsyncThunk(
  "/categories/",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(`${baseUrl}/categories/${data}`);

      return { getCategories2ByID: response?.data };
    } catch (error) {}
  }
);

export const GetWishlist: any = createAsyncThunk(
  "/wishlists/in_wishlist",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(
        `${baseUrl}/wishlists/in_wishlist/${data}`
      );

      return { getwishlist: response?.data };
    } catch (error) {}
  }
);

export const GetMeAPI: any = createAsyncThunk("/Me", async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(`${baseUrl}/me`, {
      headers: {
        Authorization: `Bearer ${token}`, // Add the token to the headers
      },
    });

    return { getMe: response?.data };
  } catch (error) {
    throw error;
  }
});
export const PutAddressAPI: any = createAsyncThunk(
  "/PutAddressAPI",
  async (data: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(`${baseUrl}/users/${data?.id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the headers
        },
      });

      return { putAddressAPI: response?.data };
    } catch (error) {
      throw error;
    }
  }
);

export const GetProductData: any = createAsyncThunk(
  "/products",
  async (data: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(
        `${baseUrl}/products/${data?.id1}?currency=${data?.currency}`
      );

      return { getProductData: response?.data };
    } catch (error) {
      throw error;
    }
  }
);

export const GetCategoriesData: any = createAsyncThunk(
  "/categoriesAll",
  async (data: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(`${baseUrl}/categories`);

      return { getCategories: response?.data };
    } catch (error) {
      throw error;
    }
  }
);

export const GetCategoriesByName: any = createAsyncThunk(
  "/categoriesByName",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(
        `${baseUrl}/get-product-by-category/${data.category}?currency=${data.currency}`
      );

      return { getCategoriesByName: response?.data?.data };
    } catch (error) {}
  }
);
export const PostCartItem: any = createAsyncThunk(
  "/CartItem",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.post(`${baseUrl}/products/cart`, data);
      return { postCartItem: response?.data };
    } catch (error) {}
  }
);
export const PutCartItem: any = createAsyncThunk(
  "/CartItemPut",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.put(
        `${baseUrl}/products/cart/${data.idOfPut}`,
        data
      );

      // console.log(response.data, "resof cart");
      return { putCartItem: response?.data };
    } catch (error) {}
  }
);
export const DeleteCartItem: any = createAsyncThunk(
  "/deleteCartItem",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.delete(`${baseUrl}/products/cart/${data}`);

      // console.log(response.data, "resof cart");
      return { deleteCartItem: response?.data };
    } catch (error) {}
  }
);

export const GetCartItem: any = createAsyncThunk(
  "/CartItemData",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    // console.log(data,"data.userId");

    try {
      const response = await axios.get(
        `${baseUrl}/products/cart?currency=${data.currencyData}&user_id=${data?.userId}`
      );

      const filteredData = response?.data?.data?.filter(
        (item: any) => item?.product?.is_approved === 1
      );

      const FilterResponseData = {
        shipping_charge: response?.data?.shipping_charge,
        taxes: response?.data?.taxes,
        data: filteredData,
        success: response?.data?.success,
      };
      //   console.log(FilterResponseData, 'FilterResponseData')
      // console.log ( response?.data, 'response?.data')
      // return { getCartItem: response?.data };
      return { getCartItem: FilterResponseData };
    } catch (error) {}
  }
);
export const GetCartItemWithTax: any = createAsyncThunk(
  "/GetCartItemWithTax",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(
        `${baseUrl}/products/cart?currency=${data.currencyData}&user_id=${data.userId}&country=${data?.country}&state=${data?.state}`
      );
      const filteredData = response?.data?.data?.filter(
        (item: any) => item?.product?.is_approved === 1
      );

      const FilterResponseData = {
        shipping_charge: response?.data?.shipping_charge,
        taxes: response?.data?.taxes,
        data: filteredData,
        success: response?.data?.success,
      };

      // return { getCartItem: response?.data };
      return { getCartItem: FilterResponseData };
    } catch (error) {}
  }
);
export const GetCartItemWithTaxGuest: any = createAsyncThunk(
  "/GetCartItemWithTax",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(
        `${baseUrl}/products/cart?session_id=${data?.sessionId}&currency=${data?.currencyData}&country=${data?.country}&state=${data?.state}`
      ); // console.log(response.data, "resof cart");

      const filteredData = response?.data?.data?.filter(
        (item: any) => item?.product?.is_approved === 1
      );

      const FilterResponseData = {
        shipping_charge: response?.data?.shipping_charge,
        taxes: response?.data?.taxes,
        data: filteredData,
        success: response?.data?.success,
      };
      // return { getCartItem: response?.data };
      return { getCartItem: FilterResponseData };
    } catch (error) {}
  }
);

export const GetCartItemBySessionId: any = createAsyncThunk(
  "/CartItemDataSessionID",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    // console.log(data,"data of user");

    try {
      const response = await axios.get(
        `${baseUrl}/products/cart?currency=${data.currencyData}&session_id=${data.userId}`
      );

      // console.log(response.data, "resof cart");
      const filteredData = response?.data?.data?.filter(
        (item: any) => item?.product?.is_approved === 1
      );

      const FilterResponseData = {
        shipping_charge: response?.data?.shipping_charge,
        taxes: response?.data?.taxes,
        data: filteredData,
        success: response?.data?.success,
      };

      // return { getCartItemBySessionId: response?.data };
      return { getCartItemBySessionId: FilterResponseData };
    } catch (error) {}
  }
);

export const GetWishlistAPI: any = createAsyncThunk(
  "/wishlist/getapi/redux",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${baseUrl}/wishlists/?currency=${data}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the headers
          },
        }
      );
      return { getWishlistAPI: response?.data?.data };
    } catch (error) {
      throw error;
    }
  }
);

export const GetAllProducts: any = createAsyncThunk(
  "/GetAllProducts",
  async (data: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${baseUrl}/products?limit=500`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the headers
        },
      });

      return { getAllProducts: response?.data };
    } catch (error) {
      throw error;
    }
  }
);
export const GetAllBestSellerProducts: any = createAsyncThunk(
  "/getAllBestSellerProducts",
  async (data: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${baseUrl}/bestseller-products`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the headers
        },
      });

      return { getAllBestSellerProducts: response?.data };
    } catch (error) {
      throw error;
    }
  }
);
export const GetAllBestSellerProductsPagination: any = createAsyncThunk(
  "/getAllBestSellerProductsPagination",
  async (data: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${baseUrl}/bestseller-products?page=${data}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the headers
          },
        }
      );

      return { getAllBestSellerProductsPagination: response?.data };
    } catch (error) {
      throw error;
    }
  }
);

export const GetTypes: any = createAsyncThunk("/types/8", async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const response = await axios.get(`${baseUrl}/types/8`);

    return { getTypes: response?.data };
  } catch (error) {}
});
export const GetGazette: any = createAsyncThunk(
  "/types/Gazette",
  async (page) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(`${baseUrl}/manufacturers?page=${page}`);

      return { getGazette: response?.data };
    } catch (error) {}
  }
);
export const GetTestiMoniaL: any = createAsyncThunk(
  "/GetTestiMoniaL",
  async () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    // const token = localStorage.getItem("token");

    try {
      // const response = await axios.get(`${baseUrl}/testimonials`);
      const response = await axios.get(`${baseUrl}/testimonials`, {
        headers: {
          Authorization: `Bearer 901|6kHyjzrhphlCYW64rJbZk6WxeJWC9mAKVWoJ1X3C`, // Add the token to the headers
        },
      });
      // console.log(response?.data, 'res')
      return { getTestiMoniaL: response?.data };
    } catch (error) {}
  }
);

export const GetAuthors: any = createAsyncThunk("/getauthors", async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const response = await axios.get(`${baseUrl}/authors`);

    // console.log(response?.data, 'res')
    return { getAuthors: response?.data?.data };
  } catch (error) {}
});

export const GetSubPageData: any = createAsyncThunk(
  "/categories/SubPageData",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(`${baseUrl}/categories/${data}`);

      return { getSubPageData: response?.data };
    } catch (error) {}
  }
);

export const GetSubPageCategoryData: any = createAsyncThunk(
  "/categories/getSubPageCategoryData",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(
        `${baseUrl}/get-product-by-mcategory/${data?.id}/${data?.subid}?currency=${data?.currency}${data?.sortBy}`
      );

      return { getSubPageCategoryData: response?.data };
    } catch (error) {}
  }
);

export const GetState: any = createAsyncThunk("/getState", async (id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  if (id) {
    try {
      const response = await axios.get(`${baseUrl}/getState/${id}`);

      // console.log(response?.data, 'res')
      return { getState: response?.data?.data };
    } catch (error) {}
  }
});
export const GetStateBillingAddress: any = createAsyncThunk(
  "/getStateBillingAddress",
  async (id: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    if (id) {
      try {
        const response = await axios.get(`${baseUrl}/getState/${id}`);

        // console.log(response?.data, 'res')
        return { getStateBillingAddress: response?.data?.data };
      } catch (error) {}
    }
  }
);
export const GetCountry: any = createAsyncThunk("/getCountry", async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const response = await axios.get(`${baseUrl}/getCountry`);

    // console.log(response?.data, 'res')
    return { getCountry: response?.data?.data };
  } catch (error) {}
});
export const GetCity: any = createAsyncThunk("/getCity", async (id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const response = await axios.get(`${baseUrl}/getCity/${id}`);

    // console.log(response?.data, 'res')
    return { getCity: response?.data?.data };
  } catch (error) {}
});
export const GetCityBillingAddress: any = createAsyncThunk(
  "/getCityBillingAddress",
  async (id: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(`${baseUrl}/getCity/${id}`);

      // console.log(response?.data, 'res')
      return { getCityBillingAddress: response?.data?.data };
    } catch (error) {}
  }
);

export const PostNotify: any = createAsyncThunk(
  "/PostNotify",
  async (data: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.post(`${baseUrl}/userNotify`, data);

      // console.log(response?.data, 'res')
      return { postNotify: response?.data };
    } catch (error) {}
  }
);
export const PostUpdatePasswordAPI: any = createAsyncThunk(
  "/PostUpdatePasswordAPI",
  async (data: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(`${baseUrl}/change-password`, data, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the headers
        },
      });

      return { postUpdatePasswordAPI: response?.data };
    } catch (error) {
      throw error;
    }
  }
);
export const PostReviewProduct: any = createAsyncThunk(
  "/PostReviewProduct",
  async (data: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(`${baseUrl}/reviews`, data, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the headers
        },
      });

      return { postReviewProduct: response?.data };
    } catch (error) {
      throw error;
    }
  }
);
export const GetSettings: any = createAsyncThunk(
  "/getsettings",
  async (data: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(`${baseUrl}/settings`);

      // console.log(response?.data, 'res')
      return { getSettings: response?.data };
    } catch (error) {}
  }
);
export const GetMarquee: any = createAsyncThunk(
  "/marquee",
  async (data: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(`${baseUrl}/getMarquee`);

      // console.log(response?.data, 'res')
      return { getMarquee: response?.data };
    } catch (error) {}
  }
);
export const GetCurrency: any = createAsyncThunk(
  "/getCurrency",
  async (data: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(`${baseUrl}/currency?page=1&limit=20`);
      return { getCurrency: response?.data };
    } catch (error) {}
  }
);
export const PostCouponCode: any = createAsyncThunk(
  "/PostCouponCode",
  async (data: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${baseUrl}/coupons/verify?currency=${data?.currency}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the headers
          },
        }
      );

      return { postCouponCode: response?.data };
    } catch (error) {
      throw error;
    }
  }
);
export const GetProductTrackingData: any = createAsyncThunk(
  "/GetProductTrackingData",
  async (id: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${baseUrl}/orders/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return { getProductTrackingData: response?.data };
    } catch (error) {
      throw error;
    }
  }
);
export const GetStateAndCity: any = createAsyncThunk(
  "/GetStateAndCity",
  async (data: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(`${baseUrl}/getdatafrompincode`, data);

      return { getStateAndCity: response?.data };
    } catch (error) {
      throw error;
    }
  }
);
export const GetStateAndCityForBilling: any = createAsyncThunk(
  "/GetStateAndCityForBilling",
  async (data: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(`${baseUrl}/getdatafrompincode`, data);

      return { getStateAndCityForBilling: response?.data };
    } catch (error) {
      throw error;
    }
  }
);

export const ContainerDataSlice = createSlice({
  name: "ContainerData",
  initialState: {
    getCategoriesByID: [],
    getGazette: [],
    getProductTrackingData: [],
    getSettings: [],
    getMarquee: [],
    getCurrency: [],
    getAllBestSellerProducts: [],
    getAllBestSellerProductsPagination: [],
    postReviewProduct: [],
    getStateBillingAddress: [],
    getCityBillingAddress: [],
    getStateAndCity: [],
    getMe: [],
    getCountry: [],
    getStateAndCityForBilling: [],
    getCity: [],
    getProductData: [],
    getCategories: [],
    getCategoriesByName: [],
    getCategories2ByID: [],
    getSubPageCategoryData: [],
    getwishlist: [],
    postCartItem: [],
    getCartItem: [],
    getCartItemWithTax: [],
    deleteCartItem: [],
    putCartItem: [],
    getCategoriesByLimit: [],
    getTypes: [],
    shippingData: [],
    billingData: [],
    paymentData: [],
    sameAsBillingBtn: [],
    setContactNumber: [],
    setContactName: [],
    setEmptyContactInfo: [],
    setWishListData: [],
    wishlistApiCall: [],
    getWishlistAPI: [],
    postUpdatePasswordAPI: [],
    getAllProducts: [],
    getCartItemBySessionId: [],
    setEmailIdForResetPassword: [],
    getTestiMoniaL: [],
    getAuthors: [],
    setPopularProductName: [],
    setEmptyGuestShppingAddress: [],
    setEmptyGuestBillingAddress: [],
    getSubPageData: [],
    getState: [],
    totalPayment: [],
    postCouponCode: [],
    setCountryCode: 91,
    putAddressAPI: [],
    postNotify: [],
    gazetteLoading: false,
    postCartItemLoader: false,
    deleteCartItemLoader: false,
    getSubLoading: false,
    setGuestShippingAddress: {
      zip: "",
      country: "India",
      city: "",
      state: "",
      street_address: "",
    },
    setGuestBillingAddress: {
      zip: "",
      country: "India",
      city: "",
      state: "",
      street_address: "",
    },
    setAllShopPageNo: 1,
    // setTokenForResetPassword:[]
  },
  reducers: {
    resetProductData: (state) => {
      state.getProductData = [];
    },
    resetOrderTrackingData: (state) => {
      state.getProductTrackingData = [];
    },
    resetGetStateAndCity: (state) => {
      state.getStateAndCity = [];
    },
    resetGetStateAndCityBillingAddress: (state) => {
      state.getStateAndCityForBilling = [];
    },
    resetSubPageCategoryData: (state) => {
      state.getSubPageCategoryData = [];
    },
    resetCouponData: (state) => {
      state.postCouponCode = [];
    },
    resetSubPageData: (state) => {
      state.getSubPageData = [];
    },
    resetCartData: (state) => {
      state.getCartItem = [];
    },
    resetGetMeData: (state) => {
      state.getMe = [];
    },
    resetPutCartData: (state) => {
      state.putCartItem = [];
    },
    resetPostCartData: (state) => {
      state.postCartItem = [];
    },
    resetPageCollectionData: (state) => {
      state.getCategories2ByID = [];
    },
    setShippingData: (state, action) => {
      state.shippingData = action.payload;
    },
    setBillingData: (state, action) => {
      state.billingData = action.payload;
    },
    setPaymentData: (state, action) => {
      state.paymentData = action.payload;
    },
    setBillingAsSameBtn: (state, action) => {
      state.sameAsBillingBtn = action.payload;
    },
    setContactNumberUser: (state, action) => {
      state.setContactNumber = action.payload;
    },
    setContactName: (state, action) => {
      state.setContactName = action.payload;
    },
    setEmptyContactInfo: (state, action) => {
      state.setEmptyContactInfo = action.payload;
    },
    setWishlistData: (state, action) => {
      state.setWishListData = action.payload;
    },
    WishListApiCall: (state, action) => {
      state.wishlistApiCall = action.payload;
    },
    SetEmailIdForResetPassword: (state, action) => {
      state.setEmailIdForResetPassword = action.payload;
    },
    resetEmailIdForResetPassword: (state) => {
      state.setEmailIdForResetPassword = [];
    },
    SetPopularProductName: (state, action) => {
      state.setPopularProductName = action.payload;
    },
    SetGuestShippingAddressNull: (state, action) => {
      state.setEmptyGuestShppingAddress = action.payload;
    },
    SetGuestBillingAddressNull: (state, action) => {
      state.setEmptyGuestBillingAddress = action.payload;
    },
    TotalPayment: (state, action) => {
      state.totalPayment = action.payload;
    },
    SetCountryCode: (state, action) => {
      state.setCountryCode = action.payload;
    },
    SetGuestShippingAddress: (state, action) => {
      state.setGuestShippingAddress = action.payload;
    },
    SetGuestBillingAddress: (state, action) => {
      state.setGuestBillingAddress = action.payload;
    },
    SetAllShopPageNo: (state, action) => {
      state.setAllShopPageNo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetCategoriesByID.fulfilled, (state, action: any) => {
        state.getCategoriesByID = action.payload.getCategoriesByID;
      })
      .addCase(GetGazette.pending, (state) => {
        state.gazetteLoading = true;
      })
      .addCase(GetGazette.fulfilled, (state, action: any) => {
        state.gazetteLoading = false;
        state.getGazette = action.payload.getGazette;
      })

      .addCase(GetMeAPI.fulfilled, (state, action: any) => {
        state.getMe = action.payload.getMe;
      })
      .addCase(GetProductData.fulfilled, (state, action: any) => {
        state.getProductData = action.payload.getProductData;
      })
      .addCase(GetCategoriesData.fulfilled, (state, action: any) => {
        state.getCategories = action.payload?.getCategories;
      })
      .addCase(GetCategoriesByName.fulfilled, (state, action: any) => {
        state.getCategoriesByName = action.payload.getCategoriesByName;
      })
      .addCase(GetCategories2ByID.fulfilled, (state, action: any) => {
        state.getCategories2ByID = action.payload.getCategories2ByID;
      })
      .addCase(GetSubPageCategoryData.pending, (state) => {
        state.getSubLoading = true;
      })
      .addCase(GetSubPageCategoryData.fulfilled, (state, action: any) => {
        state.getSubLoading = false;

        state.getSubPageCategoryData = action.payload.getSubPageCategoryData;
      })
      .addCase(GetSubPageData.fulfilled, (state, action: any) => {
        state.getSubPageData = action.payload.getSubPageData;
      })
      .addCase(GetWishlist.fulfilled, (state: any, action: any) => {
        state.getwishlist = action.payload.getwishlist;
      })
      .addCase(GetWishlistAPI.fulfilled, (state: any, action: any) => {
        state.getWishlistAPI = action.payload?.getWishlistAPI;
      })
      .addCase(GetSettings.fulfilled, (state: any, action: any) => {
        state.getSettings = action.payload?.getSettings;
      })
      .addCase(PostCartItem.pending, (state: any) => {
        state.postCartItemLoader = true;
      })
      .addCase(PostCartItem.fulfilled, (state: any, action: any) => {
        state.postCartItem = action.payload.postCartItem;
        state.postCartItemLoader = false;
      })
      .addCase(PostCartItem.rejected, (state: any) => {
        state.postCartItemLoader = false;
      })

      .addCase(PutCartItem.fulfilled, (state: any, action: any) => {
        state.putCartItem = action.payload?.putCartItem;
      })
      .addCase(DeleteCartItem.pending, (state: any, action: any) => {
        state.deleteCartItemLoader = true;
      })
      .addCase(DeleteCartItem.fulfilled, (state: any, action: any) => {
        state.deleteCartItem = action.payload.deleteCartItem;
        state.deleteCartItemLoader = false;
      })
      .addCase(DeleteCartItem.rejected, (state: any) => {
        state.deleteCartItemLoader = false;
      })
      .addCase(GetCartItem.fulfilled, (state: any, action: any) => {
        state.getCartItem = action.payload.getCartItem;
      })
      .addCase(GetCartItemWithTax.fulfilled, (state: any, action: any) => {
        state.getCartItem = action.payload.getCartItem;
      })
      .addCase(GetCurrency.fulfilled, (state: any, action: any) => {
        state.getCurrency = action.payload.getCurrency;
      })
      .addCase(GetCartItemBySessionId.fulfilled, (state: any, action: any) => {
        state.getCartItemBySessionId = action.payload.getCartItemBySessionId;
      })
      .addCase(GetCategoriesByLimit.fulfilled, (state: any, action: any) => {
        state.getCategoriesByLimit = action.payload.getCategoriesByLimit;
      })
      .addCase(GetTypes.fulfilled, (state: any, action: any) => {
        state.getTypes = action.payload.getTypes;
      })
      .addCase(GetAllProducts.fulfilled, (state: any, action: any) => {
        state.getAllProducts = action.payload.getAllProducts;
      })
      .addCase(
        GetAllBestSellerProducts.fulfilled,
        (state: any, action: any) => {
          state.getAllBestSellerProducts =
            action.payload.getAllBestSellerProducts;
        }
      )
      .addCase(
        GetAllBestSellerProductsPagination.fulfilled,
        (state: any, action: any) => {
          state.getAllBestSellerProductsPagination =
            action.payload.getAllBestSellerProductsPagination;
        }
      )
      .addCase(GetTestiMoniaL.fulfilled, (state: any, action: any) => {
        state.getTestiMoniaL = action.payload.getTestiMoniaL;
      })
      .addCase(GetAuthors.fulfilled, (state: any, action: any) => {
        state.getAuthors = action.payload.getAuthors;
      })
      .addCase(GetState.fulfilled, (state: any, action: any) => {
        state.getState = action.payload.getState;
      })
      .addCase(GetStateBillingAddress.fulfilled, (state: any, action: any) => {
        state.getStateBillingAddress = action.payload.getStateBillingAddress;
      })
      .addCase(GetCountry.fulfilled, (state: any, action: any) => {
        state.getCountry = action.payload.getCountry;
      })
      .addCase(GetCity.fulfilled, (state: any, action: any) => {
        state.getCity = action.payload.getCity;
      })
      .addCase(GetMarquee.fulfilled, (state: any, action: any) => {
        state.getMarquee = action.payload.getMarquee;
      })
      .addCase(GetCityBillingAddress.fulfilled, (state: any, action: any) => {
        state.getCityBillingAddress = action.payload.getCityBillingAddress;
      })
      .addCase(PutAddressAPI.fulfilled, (state: any, action: any) => {
        state.putAddressAPI = action.payload.putAddressAPI;
      })
      .addCase(PostNotify.fulfilled, (state: any, action: any) => {
        state.postNotify = action.payload.postNotify;
      })
      .addCase(PostUpdatePasswordAPI.fulfilled, (state: any, action: any) => {
        state.postUpdatePasswordAPI = action.payload.postUpdatePasswordAPI;
      })
      .addCase(PostCouponCode.fulfilled, (state: any, action: any) => {
        state.postCouponCode = action.payload.postCouponCode;
      })
      .addCase(GetProductTrackingData.fulfilled, (state: any, action: any) => {
        state.getProductTrackingData = action.payload.getProductTrackingData;
      })
      .addCase(GetStateAndCity.fulfilled, (state: any, action: any) => {
        state.getStateAndCity = action.payload.getStateAndCity;
      })
      .addCase(
        GetStateAndCityForBilling.fulfilled,
        (state: any, action: any) => {
          state.getStateAndCityForBilling =
            action.payload.getStateAndCityForBilling;
        }
      );
  },
});

export const { resetProductData } = ContainerDataSlice.actions;
export const { resetOrderTrackingData } = ContainerDataSlice.actions;
export const { resetSubPageData } = ContainerDataSlice.actions;
export const { resetGetStateAndCity } = ContainerDataSlice.actions;
export const { resetGetStateAndCityBillingAddress } =
  ContainerDataSlice.actions;
export const { resetSubPageCategoryData } = ContainerDataSlice.actions;
export const { resetCartData } = ContainerDataSlice.actions;
export const { resetPostCartData } = ContainerDataSlice.actions;
export const { resetPutCartData } = ContainerDataSlice.actions;
export const { resetEmailIdForResetPassword } = ContainerDataSlice.actions;
export const { resetPageCollectionData } = ContainerDataSlice.actions;
export const { setShippingData } = ContainerDataSlice.actions;
export const { SetEmailIdForResetPassword } = ContainerDataSlice.actions;
export const { SetAllShopPageNo } = ContainerDataSlice.actions;
// export const { SetTokenForResetPassword } = ContainerDataSlice.actions;
export const { setBillingData } = ContainerDataSlice.actions;
export const { setPaymentData } = ContainerDataSlice.actions;
export const { setBillingAsSameBtn } = ContainerDataSlice.actions;
export const { setContactNumberUser } = ContainerDataSlice.actions;
export const { setContactName } = ContainerDataSlice.actions;
export const { setEmptyContactInfo } = ContainerDataSlice.actions;
export const { setWishlistData } = ContainerDataSlice.actions;
export const { WishListApiCall } = ContainerDataSlice.actions;
export const { resetGetMeData } = ContainerDataSlice.actions;
export const { SetPopularProductName } = ContainerDataSlice.actions;
export const { SetGuestShippingAddressNull } = ContainerDataSlice.actions;
export const { SetGuestBillingAddressNull } = ContainerDataSlice.actions;
export const { TotalPayment } = ContainerDataSlice.actions;
export const { SetCountryCode } = ContainerDataSlice.actions;
export const { SetGuestShippingAddress } = ContainerDataSlice.actions;
export const { SetGuestBillingAddress } = ContainerDataSlice.actions;
export const { resetCouponData } = ContainerDataSlice.actions;

export default ContainerDataSlice.reducer;
