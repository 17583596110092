import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const GetCategories: any = createAsyncThunk(
  "/categories",
  async (data: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response =
        await axios.get(`${baseUrl}/get-product-by-category/${data.category}?currency=${data.currency}&page=${data.page}${data?.params}
    `);

      return { getCategories: response?.data };
    } catch (error) {}
  }
);

export const AllShopDataSlice = createSlice({
  name: "AllShopData",
  initialState: {
    getCategories: [],
  },
  reducers: {
    resetALLShopData: (state) => {
      state.getCategories = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetCategories.fulfilled, (state, action: any) => {
      state.getCategories = action.payload?.getCategories;
    });
  },
});
export const { resetALLShopData } = AllShopDataSlice.actions;

export default AllShopDataSlice.reducer;
