// formSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FormState {
  UserData: {
     number:any,
     email:any,
     name:string,
     selectedOption: string;
  };
}

const initialState: FormState = {
  UserData: {
  number:'',
  email:'',
  name:'',
  selectedOption: ''
  },
};

const userinfoslice = createSlice({
  name: "form",
  initialState,
  reducers: {
    userupdateFeild: (state:any, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.UserData[name] = value;
    },
    setRadioOption: (state, action: PayloadAction<string>) => {
      state.UserData.selectedOption = action.payload;
    }
  },
});

export const { userupdateFeild,setRadioOption } = userinfoslice.actions;
export default userinfoslice.reducer;
