import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const GetCategoriesByID: any = createAsyncThunk(
  "/categories",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    if (data?.id && data?.currency && data?.page) {
      try {
        const response = await axios.get(
          `${baseUrl}/get-product-by-category/${data?.id}?currency=${data?.currency}&page=${data?.page}`
        );

        // console.log(response?.data, 'res')
        return { getCategoriesByID: response?.data };
      } catch (error) {}
    }
  }
);
export const GetCategories2ByID: any = createAsyncThunk(
  "/categories2",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(`${baseUrl}/categories/${data}`);

      // console.log(response?.data, 'res')
      return { getCategories2ByID: response?.data };
    } catch (error) {}
  }
);
export const GetMeAPI: any = createAsyncThunk("/Me", async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${baseUrl}/me`, {
      headers: {
        Authorization: `Bearer ${token}`, // Add the token to the headers
      },
    });

    return { getMe: response?.data };
  } catch (error) {
    // Handle errors here
    // You might want to dispatch an action to handle the error in your Redux store
    throw error;
  }
});
export const GetProductData: any = createAsyncThunk(
  "/products",
  async (data: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(
        `${baseUrl}/products/${data?.id1}?currency=${data?.currency}`
      );
      return { getProductData: response?.data };
    } catch (error) {
      throw error;
    }
  }
);
export const GetCategoriesData: any = createAsyncThunk(
  "/categoriesAll",
  async (data: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(`${baseUrl}/categories`);

      return { getCategories: response?.data };
    } catch (error) {
      throw error;
    }
  }
);

export const GetCategoriesByName: any = createAsyncThunk(
  "/categoriesByName",
  async (data: any, thunkAPI) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(
        `${baseUrl}/get-product-by-category/${data.category}?currency=${data.currency}`
      );

      return { getCategoriesByName: response?.data?.data };
    } catch (error) {}
  }
);

export const GetOrders: any = createAsyncThunk(
  "orders/fetchOrders",
  async (data, thunkAPI) => {
    const token = localStorage.getItem("token");
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(`${baseUrl}/orders?&page=${data}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the Authorization header
        },
      });

      return { getorders: response?.data 
       };
    } catch (error) {
      // Handle errors here, you might want to return an error message or handle it differently.
      throw error;
    }
  }
);

export const OrderDataSlice = createSlice({
  name: "GetOrderData",
  initialState: {
    getorders: [],
    lastPage:[]
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetOrders.fulfilled, (state, action: any) => {
      state.getorders = action.payload.getorders;
    });
  },
});

export default OrderDataSlice.reducer;
