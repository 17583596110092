// formSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FormState {
  billingformData: {
    city: string;
    zip: string;
    street_address: string;
    state: string;
    country: string;
  };
}

const initialState: FormState = {
  billingformData: {
    city: "",
    zip: "",
    street_address: "",
    state: "",
    country: "",
  },
};

const billingFormslice = createSlice({
  name: "billingform",
  initialState,
  reducers: {
    updatebillingForm: (
      state: any,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      const { name, value } = action.payload;

      state.billingformData[name] = value;
    },

    setCountry: (state, action: PayloadAction<string>) => {
      state.billingformData.country = action.payload;
    },
    setCity: (state, action: PayloadAction<string>) => {
      state.billingformData.city = action.payload;
    },
    setState: (state, action: PayloadAction<string>) => {
      state.billingformData.state = action.payload;
    },

    clearbillingformdata: (state) => {
      return initialState;
    },
  },
});

export const { updatebillingForm, setCountry,clearbillingformdata , setState , setCity } = billingFormslice.actions;
export default billingFormslice.reducer;
