import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useEffect } from "react";

// Define the initial state for the wishlist
interface WishlistItem {
  id: any;
  name: string;
  image: string;
  price: number;
}

const initialState: WishlistItem[] = [];

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    addToWishlist: (state, action: PayloadAction<WishlistItem>) => {
      state.push(action.payload);
    },
    removeFromWishlist: (state, action: PayloadAction<WishlistItem>) => {
      return state.filter((item:any) => item.id !== action.payload);
      const itemToRemove = action.payload;
      return state.filter(item => item.id !== itemToRemove.id);
    },
     
  },
});

  
export const { addToWishlist, removeFromWishlist } = wishlistSlice.actions;
export default wishlistSlice.reducer;
