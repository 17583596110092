import { createSlice, PayloadAction } from "@reduxjs/toolkit";


const currencyConversion: Record<string, number> = {
  INR: 1,
  USD: 0.012,
  EUR: 0.011,
  GBP: 0.0099,
  SGD: 0.016,
};

const convertPrice = (
  price: number,
  fromCurrency: string,
  toCurrency: string
) => {
  return (
    price * (currencyConversion[toCurrency] / currencyConversion[fromCurrency])
  );
};

interface CartItem {
  id: number;
  name: string;
  price: number;
  quantity: number;
  image: any;
  pivot: any;
  slug: any;
}

interface CartState {
  items: CartItem[];
  totalItems: number; // Change to a specific data type (e.g., number) for clarity
  totalPrice: number; // Add this property
  selectedCurrency: string;
  pivot: any;
  slug: any;

}

const initialState: CartState = {
  items: [],
  totalItems: 0,
  totalPrice: 0, // Initialize totalPrice to 0
  selectedCurrency: "INR",
  pivot: {},
  slug: ''
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<CartItem>) => {
      const existingItemIndex = state.items.findIndex(
        (item) => item.id === action.payload.id
      );

      if (existingItemIndex !== -1) {
        // Item already exists in the cart, update its quantity and price
        state.items[existingItemIndex].quantity += action.payload.quantity;
        state.items[existingItemIndex].price =
          state.items[existingItemIndex].quantity *
          state.items[existingItemIndex].price;
      } else {
        // Item is not in the cart, add it
        state.items.push(action.payload);
      }

      // Update totalItems and totalPrice
      state.totalItems = state.items.reduce(
        (total, item) => total + item.quantity,
        0
      );
      state.totalPrice = state.items.reduce(
        (total, item) => total + item.price,
        0
      );

      
    },

    removeFromCart: (state, action: PayloadAction<number>) => {
      const removedItem = state.items.find(
        (item) => item.id === action.payload
      );

      state.items = state.items.filter((item) => item.id !== action.payload);

      // Update totalItems and totalPrice
      state.totalItems = state.items.reduce(
        (total, item) => total + item.quantity,
        0
      );
      state.totalPrice = state.items.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );
    },
    addItem: (state, action: PayloadAction<CartItem>) => {
      const existingItem = state.items.find((item) => item.id === action.payload.id);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.items.push({ ...action.payload, quantity: 1 });
      }
      state.totalPrice += action.payload.price;
    },

    incrementQuantity: (state, action: PayloadAction<number>) => {
      const item = state.items.find((item) => item.id === action.payload);
      if (item) {
        item.quantity += 1;
      }

      // Update totalPrice
      state.totalPrice = state.items.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );



    },
    decrementQuantity: (state, action: PayloadAction<number>) => {
      const item = state.items.find((item) => item.id === action.payload);
      if (item && item.quantity >= 1) {
        item.quantity -= 1;
      }
      // Update totalPrice
      state.totalPrice = state.items.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );


    },

    clearCart: (state) => {
      state.items = [];
      state.totalItems = 0;
      state.totalPrice = 0;
    },

   
    changeCurrency: (state, action: PayloadAction<string>) => {
      const newCurrency = action.payload;

      // Check if the new currency is valid
      if (currencyConversion[newCurrency] !== undefined) {
        // Convert prices for each item
        const updatedItems = state.items.map((item) => {
          const convertedPrice = convertPrice(
            item.price,
            state.selectedCurrency,
            newCurrency
          ).toFixed(2); // Round to 2 decimal places
          return { ...item, price: parseFloat(convertedPrice) }; // Convert back to a float

        });

        // Recalculate the total price
        const newTotalPrice = updatedItems.reduce(
          (total, item: any) => total + parseFloat(item.price),
          0
        );

        // Update the Redux state
        state.selectedCurrency = newCurrency;
        state.items = updatedItems;
        state.totalPrice = parseFloat(newTotalPrice.toFixed(2)); // Round the total price as 

      } else {
        // Handle the case where the currency is not in the conversion table
        console.error(`Invalid currency: ${newCurrency}`);
      }
    }, 
    
  },
});

export const {
  addToCart,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
  clearCart,
  addItem,
  // setCurrency,
  changeCurrency,
} = cartSlice.actions;

export default cartSlice.reducer;
