import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

interface ApiState {
  data: any;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: ApiState = {
  data: null,
  loading: 'idle',
  error: null,
};

export const fetchApiData = createAsyncThunk('api/fetchData', async (endpoint: string) => {
  const response = await fetch(endpoint);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
});

const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchApiData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchApiData.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchApiData.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message || 'An error occurred';
      });
  },
});

export default apiSlice.reducer;
