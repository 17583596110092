import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
// import Footer from "shared/Footer/Footer";
import { Toaster } from "react-hot-toast";
import Loader from "components/Loader/Loader";

const PageHome = lazy(() => import("containers/PageHome/PageHome"));
const ProductDetailPage = lazy(
  () => import("containers/ProductDetailPage/ProductDetailPage")
);
const PageCollection = lazy(() => import("containers/PageCollection"));
const AccountPage = lazy(() => import("containers/AccountPage/AccountPage"));
const CartPage = lazy(() => import("containers/ProductDetailPage/CartPage"));
const CheckoutPage = lazy(() => import("containers/PageCheckout/CheckoutPage"));
const BlogPage = lazy(() => import("containers/BlogPage/BlogPage"));
const BlogSingle = lazy(() => import("containers/BlogPage/BlogSingle"));
const AccountOrder = lazy(() => import("containers/AccountPage/AccountOrder"));
const OrderTrackingPage = lazy(
  () => import("containers/TrackingPage/OrderTrackingPage")
);
const PageContact = lazy(() => import("containers/PageContact/PageContact"));
const PageAbout = lazy(() => import("containers/PageAbout/PageAbout"));
const PageSignUp = lazy(() => import("containers/PageSignUp/PageSignUp"));
const PageLogin = lazy(() => import("containers/PageLogin/PageLogin"));
const AccountPass = lazy(() => import("containers/AccountPage/AccountPass"));
const PageNewSection = lazy(() => import("containers/PageNew/PageNewSection"));
const PageResetPass = lazy(
  () => import("containers/PageResetPass/PageResetPass")
);
const PageBestSeller = lazy(
  () => import("containers/BestSellerPage/PageBestSeller")
);
const PageForgotPass = lazy(
  () => import("containers/PageForgotPass/PageForgotPass")
);
const PrivecyPolicy = lazy(() => import("components/CmsSection/PrivecyPolicy"));
const TermsAndCondations = lazy(
  () => import("components/CmsSection/TermsAndCondations")
);
const AllShops = lazy(() => import("components/Allshops/AllShops"));
const WishlistPage = lazy(() => import("components/Wishlists/WishlistPage"));
const SubPageCollection = lazy(() => import("containers/SubPageCollection"));
const HeaderLogged = lazy(() => import("components/Header/HeaderLogged"));
const RouteGuard = lazy(() => import("./Routeguard"));
const Footer = lazy(() => import("shared/Footer/Footer"));

const token = localStorage.getItem("token");

export const pages: Page[] = [
  { path: "/", component: PageHome },
  { path: "/home-header-2", component: PageHome },
  { path: "/product-detail/:id1", component: ProductDetailPage },

  { path: "/:id", component: PageCollection },

  { path: "/account", component: token ? AccountPage : PageHome },
  {
    path: "/account-change-password",
    component: token ? AccountPass : PageHome,
  },

  { path: "/account-my-order", component: token ? AccountOrder : PageHome },
  { path: "/cart", component: CartPage },
  { path: "/checkout", component: CheckoutPage },
  { path: "/blog", component: BlogPage },
  { path: "/blog-single/:id", component: BlogSingle },
  { path: "/tracking-order/:id", component: OrderTrackingPage },
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/new", component: PageNewSection },
  { path: "/:id/:subid", component: SubPageCollection },
  { path: "/wishlist", component: WishlistPage },
  { path: "/privacy", component: PrivecyPolicy },
  { path: "/terms", component: TermsAndCondations },
  { path: "/allshops", component: AllShops },
  { path: `/signup`, component: token ? PageHome : PageSignUp },
  { path: `/forgot-pass`, component: PageForgotPass },
  { path: `/reset-pass/:tokenId/:emailID`, component: PageResetPass },
  { path: "/login", component: token ? PageHome : PageLogin },
  { path: "/best-seller", component: PageBestSeller },
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <Toaster />
      <ScrollToTop />

      <Suspense fallback={<Loader />}>
        <HeaderLogged />

        <Routes>
          {pages.map(({ component: Component, path }, index) => {
            return <Route key={index} element={<Component />} path={path} />;
          })}
          <Route path="*" Component={RouteGuard} />
        </Routes>
        <Footer />
      </Suspense>
    </BrowserRouter>
  );
};

export default MyRoutes;
