// formSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FormState {
  shippingFormData: {
    city: string;
    zip: string;
    street_address: string;
    state: string;
    country: string;
  };
}

const initialState: FormState = {
  shippingFormData: {
    city: "",
    zip: "",
    street_address: "",
    state: "",
    country: "",
  },
};

const shippingFormSlice = createSlice({
  name: "form",
  initialState,
  reducers: {

    setCountry: (state, action: PayloadAction<string>) => {
      state.shippingFormData.country = action.payload;
    },
    setCity: (state, action: PayloadAction<string>) => {
      state.shippingFormData.city = action.payload;
    },
    setState: (state, action: PayloadAction<string>) => {
      state.shippingFormData.state = action.payload;
    },

    updateField: (state:any, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.shippingFormData[name] = value;
    },
    clearshippingdata: (state) => {
      return initialState;
    },
  },
});

export const { updateField,setCountry,clearshippingdata, setState, setCity } = shippingFormSlice.actions;
export default shippingFormSlice.reducer;
